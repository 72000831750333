<template>
  <div class="help-main">
    <div class="help-menu">
      <div class="help-title">帮助中心</div>
      <!-- <div class="help-list">
        <router-link
          class="help-item"
          :to="item.path"
          v-for="(item, index) in noviceMenu" 
          :key="index"
          >{{ item.name }}</router-link
        >
      </div> -->
      <el-collapse v-model="activeName" accordion>
  <el-collapse-item title="新手上路" name="1">
     <div class="help-list">
    <router-link
          class="help-item"
          :to="item.path"
          v-for="(item, index) in noviceMenu" 
          :key="index"
          >{{ item.name }}</router-link
        >
     </div>
  </el-collapse-item>
  <el-collapse-item title="配送服务" name="2">
     <div class="help-list">
    <router-link
          class="help-item"
          :to="item.path"
          v-for="(item, index) in distribMenu" 
          :key="index"
          >{{ item.name }}</router-link
        >
     </div>
  </el-collapse-item>
  <el-collapse-item title="售后服务" name="3">
     <div class="help-list">
    <router-link
          class="help-item"
          :to="item.path"
          v-for="(item, index) in postsaleMenu" 
          :key="index"
          >{{ item.name }}</router-link
        >
     </div>
  </el-collapse-item>
  <el-collapse-item title="海贝计划" name="4">
     <div class="help-list">
    <router-link
          class="help-item"
          :to="item.path"
          v-for="(item, index) in haibeiMenu" 
          :key="index"
          >{{ item.name }}</router-link
        >
     </div>
  </el-collapse-item>
</el-collapse>
    </div>
    <div class="help-router">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      noviceMenu:[
         {
          name: "个人信息完善",
          path: "/helpCenter/helpPerson",
        },
        {
          name: "海尔账号及权益",
          path: "/helpCenter/helpAccount",
        },
        {
          name: "常见兑换问题",
          path: "/helpCenter/helpQuestion",
        },
        {
          name: "隐私权政策",
          path: "/helpCenter/helpPrivacy",
        }
      ],
      distribMenu:[
        {
          name: "订单查询",
          path: "/helpCenter/helpOrder",
        },
        {
          name: "配送范围",
          path: "/helpCenter/helpDistrib",
        },
        {
          name: "商品验货与签收",
          path: "/helpCenter/helpAccept",
        },
        {
          name: "商品发票说明",
          path: "/helpCenter/helpBill",
        },
      ],
      postsaleMenu:[
        {
          name: "退换货规则",
          path: "/helpCenter/helpPlan",
        },
        {
          name: "售后联系方式",
          path: "/helpCenter/helpContact",
        },
      ],
      haibeiMenu:[
     
        {
          name: "关于我们",
          path: "/helpCenter/helpAboutUs",
        },
            {
          name: "积分查询",
          path: "/helpCenter/helpSearch",
        },
        {
          name: "积分使用及有效期",
          path: "/helpCenter/usePoint",
        },
         {
          name: "积分获取方式",
          path: "/helpCenter/getPoint",
        },
        // {
        //   name: "招商计划",
        //   path: "/helpCenter/helpProject",
        // },
      ],
    };
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.help-main {
  padding-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

  .help-menu {
    width: 190px;
    height: 570px;
    background: #fff;
    margin-right: 20px;

    .help-title {
      width: 190px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      background: #71a2f9;
      font-size: 16px;
      color: #fff;
    }

    .help-list {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .help-item {
        text-align: center;
        height: 40px;
        font-size: 14px;
        transition: color 0.1s linear;
        line-height: 40px;
        // font-weight: bold;
      }

      .help-item:hover {
        color: #2283e2;
        transition: color 0.1s linear;
      }
    }
  }

  .help-router {
    padding: 20px;
    background: #fff;
    flex: 1;
    height: auto;
  }
}

.router-link-active {
  color: #2283e2;
}
/deep/.el-collapse-item__header{
  width: 190px;
      height: 35px;
      line-height: 35px;
     padding-left: 35%;
      background: #71a2f9;
      font-size: 14px;
      color: #fff;
      font-size: 16px;
}
</style>
